<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
	error: {
		type: Object as PropType<NuxtError>,
		required: true,
	},
});

useSeoMeta({
	title: `${props.error.statusCode} ${props.error.statusMessage}`,
	description: props.error.message,
});

useHead({
	htmlAttrs: {
		lang: 'en',
	},
});
</script>

<template>
	<div>
		<UMain>
			<UContainer>
				<UPage>
					<UPageError :error="error" />
				</UPage>
			</UContainer>
		</UMain>

		<UNotifications />
	</div>
</template>
