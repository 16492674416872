import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.7.42_@_7a654ayoqw2prcboq3tl4xbml4/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}