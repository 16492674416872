import revive_payload_client_fsUuI4Wams from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_o06FtpSDSw from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0EqrxOx76X from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8upjzaevAW from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_egXjt9myB0 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_emj3uHUjGO from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_yRipFz9u2R from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_h169OlWqVA from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.6.3_vue@3.4.38_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_rGILO74Zaa from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_fkwtwt3r2rlu532goj4dilhgim/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_jDPMr7L2B4 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.3.4_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_PLDm2XNOVW from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.7.42_@swc_dljco2fgqdsf2z33xrjzti6oji/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_57inMx5nhE from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.7.42_@swc_dljco2fgqdsf2z33xrjzti6oji/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_bUS5HpfJkz from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.7.42_@swc_dljco2fgqdsf2z33xrjzti6oji/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_tP6NBBbDCP from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_PhA5oNtNQ6 from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.4.1_@types+node@22.4.0_terser@5.31.6__vue@3.4.38_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_ShLr93mi1C from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.7.42_@_7a654ayoqw2prcboq3tl4xbml4/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_b3RBD6uWUH from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.7.42_@_7a654ayoqw2prcboq3tl4xbml4/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_N5Bdrtp5VA from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.7.42_@_7a654ayoqw2prcboq3tl4xbml4/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import sentry_client_a4hAB0MOGi from "/app/apps/app/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_fsUuI4Wams,
  unhead_o06FtpSDSw,
  router_0EqrxOx76X,
  payload_client_8upjzaevAW,
  navigation_repaint_client_egXjt9myB0,
  check_outdated_build_client_emj3uHUjGO,
  chunk_reload_client_yRipFz9u2R,
  plugin_vue3_h169OlWqVA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rGILO74Zaa,
  session_client_jDPMr7L2B4,
  slideovers_PLDm2XNOVW,
  modals_57inMx5nhE,
  colors_bUS5HpfJkz,
  plugin_client_tP6NBBbDCP,
  plugin_PhA5oNtNQ6,
  scrollbars_client_ShLr93mi1C,
  presets_b3RBD6uWUH,
  variables_N5Bdrtp5VA,
  sentry_client_a4hAB0MOGi
]