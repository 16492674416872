export const useAuthStore = defineStore('Auth', () => {
	const { loggedIn, user, session, clear, fetch } = useUserSession();

	const status = ref<'idle' | 'login' | 'logout'>('idle');

	const logIn = async (username: string, password: string) => {
		status.value = 'login';
		await $fetch('/api/auth/login', { method: 'POST', body: { email: username, password } });
		await fetch();
	};

	const logOut = async () => {
		status.value = 'logout';
		await clear();
		navigateTo('/login');
		status.value = 'idle';
	};

	return { logIn, logOut, loggedIn, user, session, clear, fetch, status };
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
